//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.$nuxt.$emit('currentPage', 'blog')
    this.$nuxt.$emit('errorPage', true)
    /* Scroll view to top */
    const layout = document.getElementById('layout-root-container')
    layout.scrollIntoView()
  },
  beforeDestroy () {
    this.$nuxt.$emit('currentPage', '')
    this.$nuxt.$emit('errorPage', false)
  }
}
