//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      showBubble: false
    }
  },
  computed: {
    isCareers () {
      return typeof this.$route.path === 'string' && !this.$route.path.includes('about-us/careers')
    }
  }
}
