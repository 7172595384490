import Vue from 'vue'
import JSEncrypt from 'jsencrypt/bin/jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

// 前端请求后端接口
// 公钥
const rsaPublicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2/sohmU7x9eyMFnG3qlUdlPQ22t6RKu0WyxyaLtnKX2Vzl1uDSlNvwOaAdb76AsGEIw38b4o1RW7UP2FIhFxuOOJNwzSIn3P3kUrNENYCTuKm7+RMpj3lKUNq4HVRnDshquwk77vMMJxcgzxwFYY82qLPyhOmsf1nHvK6GWHaeiqiQd61jhxBvYC27gLFE8dsPpA0tfNEF4w+aTDA87cJI0j0AIhVND0cHjqAVXsWr5o0s5lOnzEhkl9Hz6b03eAmXbrhXmP2yK8AFXAZd14i0mnEDbDbTeyqBnSz/y2i46C3vaBbChNdFt4v5TePl6xqMc8CYSyx3ZK2uPGbwba9QIDAQAB'

// 后端返回数据给前端
// 私钥
const rsaPrivateKey = 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCXQfEl3PSM+Rt0t86sZFUCPi32Onmeq1016t+xJ9fRp5pCrUmrsLiirHKLrjUiHYDUHlwL2PlZHvcz92ERZ59c/lf18MOOckUaMWEa5GQvYfHEsCQDJSCxtTeBPvFlIzPtMfiJ/FAnEZtHGxQONqmCJfxFN72YxH8tiTRBWSDw7enZdHTo5bv8dT04hMIwhYPKWtOXBaMNxrE+YGU86PHJNeNIxU5fPz7YVjU0zQdpixq0U7jYFYtfVWZA5CuZbgzSTQKfs4zX+IK5yA4REwCJ6nkF52rEsAJbM+zoTggbuh0gLyzhPBpCXrk0YfblAoh245lbZR8NSxGUB+069mrlAgMBAAECggEBAICWs87vmAuc30WL2azfIxHkEKjqr2g/4N8sjP0LlUOMS+1xalcGxwCswDiVYmBdWp96K+B6lgi49Yl9Pk1Qnb+uDtNr2qRn6u1Im3gEQFOz3ZjL8eGml7PcDpLXEttbYRK+T7/m+7ErG6oIkDVBLTwS1PN3XrzYGBJ7OSxw/Ds1hj1BXUliodFqoCAP18e9zcbPG+HCgMfXzmTsPgPxPNJKzyVz5YFv2DjVmM6I48rNGeUCIGEEBOy+WK9Q9ojTxBKVcKmU/Cy7ase9CV4XI24TbFiEYpbnpqXBS0UHvXww4upB6VQMBwueSd4b7PBSxYBsJaKB0qJk7Mt5MnEOe8UCgYEA305ExQ7aAi5nC2ENGuq4nb7LxEjuiS7UBAx9/uKBSc0CeAUy/APPwwlpl65NjbtHb6JkE2/MsfaOQLeeDAJSKgscthx6YpeJsEfw7hBTB3yfErGShZdPIDgXHLFx+l9xj0Npw2mUq0N8vlU+AQNKUQsM93MFjKZSy+v/Ao0+zyMCgYEArWc70uzTPtRAdswmiF/cBuZcGHVg83Tn+o3rjrxxefTcK7GJ/dMqXrl/3x2gmQqvVKjVksivvuqsCjQHKU8r0QgDuAf254SW8jnudioOEFxFVlSfUSGM4ErzKUdWJ2JQtZ7iaDtJJvW0kmERpmGRJvY0IKADGcKDqNMMAmjdQlcCgYEAk2W7eQN7nbL5a4ZtOxYyNFWrdO3bHgweAGoqhKMISimbdpOf+r+dVRUScP+py2MSHTroqffC+rlPXqKVHUIu7/eTxRV5Jzj1trlV7B40YB2/dnxlxe6BWTgM8XCjVmvHrpQF7On1KzMJ1Sy5F7wPQv1zWqa+w/9JabdmBU4sZlcCgYA/+ugq4jDrbLtAWgEZrmzrqtFuQNj92zPA0d6JMuSXwA4AnSgyHFj0zHLu6uqXrUaV8RmkaCHYSpQhy6KczlZ3cQKWQ3IJaHWdeFyRAuFfjfwTZ3PFJqDpHR5tK6IRNAhdivs0xDMwidYPcHtnrXY2Lv96zi92sLuNbhlvRivlTwKBgHBboDaeRAC4/ztUsFcYBGY3DTLagg/sNOVzLRIcIN3UcbRwUozFPpBKqdH8Jh2xTAbY4H903JoQw+P7zeNp93AS+ec99VAIxReoty6bfhUcYx09bmMDzIT4Q987LXCl1gp9ihIx4fQ6v8Qh+qo4O41RnID/Ke+HZiG5PuijhoGg'

const utils = {
  install (Vue) {
    Vue.prototype.$encrypt = function (txt) {
      const encryptor = new JSEncrypt()
      encryptor.setPublicKey(rsaPublicKey) // 设置公钥
      return encryptor.encrypt(txt) // 对数据进行加密
    }
    Vue.prototype.$decrypt = function (txt) {
      const encryptor = new JSEncrypt()
      encryptor.setPrivateKey(rsaPrivateKey) // 设置私钥
      return encryptor.decrypt(txt) // 对数据进行解密
    }
  }
}

Vue.use(utils)
