import { v4 as uuidv4 } from 'uuid'
import { getLocalStorage, setLocalStorage } from '@/plugins/utils'

const CryptoJS = require('crypto-js')

export const uuid = () => {
  const key = getLocalStorage('uuid') || uuidv4().replace(/-/g, '')
  setLocalStorage('uuid', key)
  return key
}

// rsa 非对称加密key
// export const rsaEncryptKey = (key) => {
//   cryptor.setPublicKey(rsaPublicKey)
//   return cryptor.encrypt(key)
// }

// // rsa 非对称解密key
// export const rsaDecryptKey = (key) => {
//   cryptor.setPrivateKey(rsaPrivateKey)
//   return cryptor.decrypt(key)
// }
const iv = CryptoJS.enc.Utf8.parse('pagsmile12345678')

// esa 对称加密数据
export const esaEncryptData = (data, encryptkey) => {
  const keys = CryptoJS.enc.Utf8.parse(encryptkey)
  const srcs = CryptoJS.enc.Utf8.parse(data)

  const encryptedData = CryptoJS.AES.encrypt(srcs, keys, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encryptedData.toString()
}
// esa 对称解密data
export const esaDecryptData = (data, Decryptkey) => {
  const keys = CryptoJS.enc.Utf8.parse(Decryptkey)
  const decrypt = CryptoJS.AES.decrypt(data, keys, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
