//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import $ from 'jquery/dist/jquery.min.js'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
// import { getSign } from '@/plugins/utils'

const corporateEmail = (value) => {
  const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(value)
  const forbiddenDomains = ['hotmail.com', 'outlook.com', 'gmail.com', 'yahoo.com']
  if (!match || !match.length || match.length < 2 || forbiddenDomains.includes(match[1].toLowerCase())) {
    return false
  }
  return true
}

export default {
  mixins: [validationMixin],
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      current_language: this.$i18n.locale,
      submited: false,
      sendingData: false,
      errorMessage: '',
      form: {
        name: '',
        topic: '',
        areaCode: this.getAreaCode(),
        phone: '',
        telephone: '',
        email: '',
        annex: null,
        description: ''
      },
      countriesList: require('@/components/contactus/countries.json'),
      countries: [],
      isSandBox: false
    }
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      topic: {
        required,
        maxLength: maxLength(100)
      },
      phone: {
        required,
        numeric,
        minLength: minLength(7),
        maxLength: maxLength(11)
      },
      email: {
        required,
        email,
        corporateEmail,
        maxLength: maxLength(255)
      },
      description: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(500)
      }
      /* annex: {
        required
      } */
    }
  },
  computed: {
    isAllSelected () {
      if ((this.serviceCountriesOpt && this.serviceCountriesOpt.length) && (this.form.usageCountries && this.form.usageCountries.length)) {
        return this.serviceCountriesOpt.length === this.form.usageCountries.length
      }
      return false
    }
  },
  mounted () {
    this.countries = this.getCountries()
    const { sandbox } = this.$route.query
    this.isSandBox = sandbox && (parseInt(sandbox) === 1)
  },
  beforeUpdate () {
    /* fix the solutions not updating on language change */
    if (this.current_language !== this.$i18n.locale) {
      this.current_language = this.$i18n.locale
      this.form.areaCode = this.getAreaCode()
      this.countries = this.getCountries()
    }
  },
  methods: {
    getCountries () {
      if (this.countriesList && this.countriesList.length) {
        const cntrs = [...this.countriesList]
        const uniqueIds = new Set()
        const uniqueCountries = cntrs.filter((country) => {
          const isDuplicate = uniqueIds.has(country.countryName)
          if (!isDuplicate) {
            uniqueIds.add(country.countryName)
            return true
          }
          return false
        })
        uniqueCountries.forEach((country) => {
          if (typeof country.code === 'string') {
            country.iso2 = country.code.substring(0, 2).toUpperCase()
            country.iso3 = country.code.substring(5).toUpperCase()
            if (this.$i18n.locale !== 'en') {
              country.displayName = this.$i18n.t(country.iso2.toLowerCase())
            } else {
              country.displayName = country.countryName
            }
          }
        })
        return uniqueCountries
      }
      return []
    },
    getAreaCode () {
      if (this.$i18n.locale === 'zh') {
        return '86'
      } else {
        return '55'
      }
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm () {
      this.submited = false
      this.sendingData = false
      this.errorMessage = ''
      this.form = {
        name: '',
        topic: '',
        areaCode: this.getAreaCode(),
        phone: '',
        telephone: '',
        email: '',
        annex: null,
        description: ''
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit () {
      // this.errorMessage = ''
      // this.$v.form.$touch()
      // // console.log('this.$v.form.$anyError', this.$v.form.$anyError)
      // if (this.$v.form.$anyError) {
      //   this.errorMessage = this.$i18n.t('contact-us.field-error')
      //   return
      // }
      // this.form.telephone = this.form.areaCode + '-' + this.form.phone
      // const data = {
      //   name: this.form.name,
      //   email: this.form.email,
      //   topic: this.form.topic,
      //   description: this.form.description,
      //   telephone: this.form.telephone,
      //   type: this.type || 0,
      //   lang: typeof this.$i18n.locale === 'string' ? this.$i18n.locale.toUpperCase() : 'PT'
      // }
      // const sign = getSign(data)
      // // console.log('data', { ...data, ...{ sign } })
      // this.sendingData = true
      // $.ajax({
      //   method: 'post',
      //   url: 'https://api.pagsmile.com/index.php?r=api%2Fconnect',
      //   data: { ...data, ...{ sign } }
      // })
      //   .then((res) => {
      //     this.sendingData = false
      //     const response = JSON.parse(res)
      //     if (Number(response.code) === 200) {
      //       this.submited = true
      //       /* baidu analytics submit start */
      //       window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      //       /* baidu analytics submit end */
      //       return true
      //     } else {
      //       this.errorMessage = this.$i18n.t('contact-us.submit-error')
      //     }
      //   }).catch(() => {
      //     // console.log('catch')
      //     this.sendingData = false
      //     this.errorMessage = this.$i18n.t('contact-us.submit-error')
      //   })
    },
    handleSupport () {
      const SupportBtn = document.getElementsByClassName('support-btn')
      if (SupportBtn.length) {
        try {
          SupportBtn[0].click()
        } catch (error) {
          console.log(error)
        }
      }
    },
    selectAllCountries () {
      const checkboxes = document.getElementsByName('usage-countries-field')
      const checkboxesArr = [...checkboxes]
      const checkedValue = !this.isAllSelected
      checkboxesArr.forEach((checkbox) => {
        if (checkbox.checked !== checkedValue) {
          checkbox.click()
        }
      })
    }
  }
}
